<template>
  <div fp-pok-ready>
    <div class="field-group">
      <div>
        <FontIcon class="icon" name="exclamation-circle-regular" />
      </div>
      <p class="title">
        {{ $t('forgotPassword.security.title') }}
      </p>
      <p :class="['description', {'cn' : isCn}]">
        {{ $t('forgotPassword.security.description') }}
      </p>
    </div>
    <div class="button-group">
      <ProgressButton button-name="continue" @click="click" />
      <ProgressButton class="nothing" button-name="cancel" @click="close" />
    </div>
  </div>
</template>

<script>
import { close } from '@/utils/clientUtil';
import { state } from '@shared/utils/storeUtils.mjs';
import Specific from '@shared/types/Specific';
import FontIcon from '@shared/components/common/FontIcon.vue';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import { siteIds } from '@/constants/base/siteMap';

export default {
  name: 'FpFreezeReady',
  components: { ProgressButton, FontIcon },
  props: {
    structure: Specific,
    modelInfo: Specific,
  },
  data() {
    return {};
  },

  computed: {
    site: state('env', 'site'),
    isWithdrawal() {
      return [siteIds.GGPOK, siteIds.EVPUKE, siteIds.GGPUKE].includes(this.site);
    },
    isMbp(){
      return [siteIds.EVPUKE, siteIds.GGPUKE].includes(this.site);
    },
    isCn(){
      const locale = this.$i18n('locale');
      return locale === 'zh-cn';
    }
  },
  methods: {
    close() {
      close(this);
    },
    click() {
      this.$emit('continue');
    }
  },
  mounted() {
    if (!this.isWithdrawal) this.$emit('continue');
    if(this.isCn) return;
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[fp-pok-ready] {.flex();.flex-dc(); .items-center(); .gap(20); flex: 1;
  .field-group {.flex();.flex-dc(); .items-center(); flex: 2;
    .icon {.fs(60);}
    .title {.mv(30) !important; .tc(); line-height: 30px;}
    .description {.tc();
      &.cn{.break-all();}
    }
  }

  //@media (@ms-down) {
  //  .title {.fs(20) !important; .mv(10) !important;}
  //  .description {.fs(14);}
  //}
  .button-group {.flex(); .flex-dc(); .gap(15); .w(100%); flex: 1;
    > [progress-button] {.w(100%); .max-w(720);
      &.nothing { .bgc(#515151);
        @{hover-press} { .bgc(#515151); }
      }
    }
  }
}
</style>